import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Box, Typography, Link, IconButton } from '@mui/material';

import logo from './assets/the_burn_primary_logo_white_RGB.png'

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';


const StyledRootBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
    }
}));

const StyledFooterBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: theme.spacing(0),
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginTop: theme.spacing(3),
    }
}));

const StyledFooterLink = styled(Link)(({ theme }) => ({
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
    }
}));

const StyledFooterCopy = styled(Box)(({ theme }) => ({
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.background.emphasis,
}));


const StyledCopy = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
    }
}));


const StyledIcons = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        marginRight: 'auto',
    }
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
}));

const TikTokIcon = ({ color = "#ffffff" }) => {
    return (
      <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width="100%"
        height="100%"
      >
        <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
      </svg>
    );
  };

export default function Footer(props) {

  return (
    <Box
      sx={{
        width: "100%",
        height: 150,
        position: "absolute", // "fixed" for a sticky footer, "absolute" for a footer that scrolls with the page
        bottom: 0,
        backgroundColor: "primary.main",
      }}
    >
      <Grid container>
        <StyledGrid item xs={12}>
            <StyledRootBox pt={3} pb={2} display="flex" flexWrap="wrap" alignItems="center">
            <Link href="/" color="inherit" underline="none">
                <Box
                    component="img"
                    sx={{
                        height: 30,
                        width: 137,
                        paddingLeft: 2,
                    }}
                    alt="Logo."
                    src={logo}
                    />
            </Link>
            <StyledFooterBox component="nav" ml="auto">
                <StyledFooterLink href="/classes/schedule" variant="body1">Schedule</StyledFooterLink>
                <StyledFooterLink href="/membership" variant="body1">Membership</StyledFooterLink>
                <StyledFooterLink href="/faq" variant="body1">FAQ</StyledFooterLink>
                <StyledFooterLink href="/about" variant="body1">About Us</StyledFooterLink>
            </StyledFooterBox>
            </StyledRootBox>
            <StyledFooterCopy pt={1} pb={2} display="flex" flexWrap="wrap" alignItems="center">
            <StyledCopy color="textSecondary" component="p" variant="caption" gutterBottom={false}>
                {`© ${new Date().getFullYear()} The Burn. All rights reserved.`}
            </StyledCopy>
            <StyledIcons ml="auto">
                <IconButton color="inherit" aria-label="Instagram" href={'https://www.instagram.com/theburnstudioankeny'}>
                    <InstagramIcon />
                </IconButton>
                <IconButton aria-label="Tiktok" href={'https://www.tiktok.com/@theburnfitness?_t=8on6SW8B7aY&_r=1'}>
                    <div style={{width: 24, height: 24}}>
                        <TikTokIcon />
                    </div>
                </IconButton>
                <IconButton color="inherit" aria-label="facebook" href={'https://www.facebook.com/theburnstudioankeny?mibextid=LQQJ4d'}>
                    <FacebookIcon />
                </IconButton>
            </StyledIcons>
            </StyledFooterCopy>
        </StyledGrid>
      </Grid>
    </Box>
  );
}