
import React from "react"
import MembershipCard from "./MembershipCard"
import DropInCard from "./DropInCard"
import { Grid, Box, Typography } from '@mui/material';
import logo from '../assets/the_burn_primary_logo_fullcolor_RGB.png'

const Membership = () => {
    let pricing = [
        {
            "id": "1",
            "title": "Pay as you go",
            "price": "$135",
            "length": "1",
            "guestPasses": 0,
            "url": "https://app.fitdegree.com/t/dashboard/fitspot/873/store/6"
        },
        {
            "id": "2",
            "title": "Semi-Annual",
            "price": "$120",
            "length": "6",
            "guestPasses": 2,
            "url": "https://app.fitdegree.com/t/dashboard/fitspot/873/store/6"
        },
        {
            "id": "3",
            "title": "Annually",
            "price": "$100",
            "length": "12",
            "guestPasses": 6,
            "url": "https://app.fitdegree.com/t/dashboard/fitspot/873/store/6"
        }
    ]
    
    let dropIn = [
        {
            "id": "1",
            "title": "Intro Offer",
            "price": "$10",
            "length": "1",
            "expires": "7 days after use",
            "description": "Unlimited for 7 days after first use",
            "url": "https://app.fitdegree.com/t/dashboard?f=873&co=266"
        }, // expires 7 days after use
        {
            "id": "1",
            "title": "10 Class Pack",
            "price": "$185",
            "length": "1",
            "expires": "12 months after purchase",
            "description": "10 classes to use at your leisure",
            "url": "https://app.fitdegree.com/t/dashboard/fitspot/873/store/7"
        }, // expires 12 months after purchase
        {
            "id": "1",
            "title": "Single Class",
            "price": "$25",
            "length": "1",
            "expires": "30 days after purchase",
            "description": "Single class to use",
            "url": "https://www.theburnstudio.com/classes/schedule"
        },
    ]

    return (
        <Grid container>
            <Grid item xs={12} paddingTop={3} textAlign={"center"} paddingBottom={1}>
                <Typography variant="h3" component="span" color="primary">Pick your Plan</Typography>
            </Grid>
            <Grid item container justifyContent='space-between' direction='row' alignItems='center'>
                <Grid item xs={12} sm={1} md={1} padding={2}/>  
                {pricing.map((item) => {
                    return (
                        <Grid item xs={12} sm={3} md={3} key={item.id} padding>
                            <MembershipCard
                                title={item.title}
                                price={item.price}
                                length={item.length}
                                guestPasses={item.guestPasses}
                                url={item.url}
                            />
                        </Grid>
                    )})}
                <Grid item xs={12} md={1} padding={2}/>  
            </Grid>
            <Grid item xs={12} paddingTop={3} textAlign={"center"} paddingBottom={1}>
                <Typography variant="h3" component="span" color="primary">Drop-In Options</Typography>
            </Grid>
            <Grid item container justifyContent='space-between' direction='row' alignItems='center'>
                <Grid item xs={12} md={1} padding={2}/>  
                {dropIn.map((item) => {
                    return (
                        <Grid item xs={12} md={3} key={item.id} padding>
                            <DropInCard
                                key={item.id}
                                title={item.title}
                                price={item.price}
                                length={item.length}
                                expires={item.expires}
                                description={item.description}
                                url={item.url}
                            />
                        </Grid>
                    )})}
                <Grid item xs={12} md={1} padding={2}/>
            </Grid>
            
            <Grid item xs={12}  textAlign={'center'} paddingTop={2} paddingBottom={2}>
                <Typography variant="overline" gutterBottom={true}>
                    Student discounts up to 20% available with valid student ID. Contact us for more information.
                </Typography>
            </Grid>
            <Grid item xs={12} sx = {{ textAlign: 'center'}}>
                <Box
                    component="img"
                    sx={{
                        height: 'auto',
                        width: '100%',
                        maxWidth: 500,
                    }}
                    alt="Logo."
                    src={logo}
                />
            </Grid>
        </Grid>
    )
}

export default Membership