import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid } from '@mui/material';

const questions = [
    {
        question: 'Will my existing membership package, including any credits or class packs, be seamlessly transferred to The Burn without any loss?',
        answer: 'Yes! All current memberships and class packs will be honored by The Burn through their expiration date. No-contract autopay prices will be honored through the end of the year. We’ll be reaching out individually to confirm your memberships; if you don’t hear from us by August 25, please reach out to us at kristi@theburnstudio.com / rose@theburnstudio.com.',
        answer2: 'We encourage you to share our membership and drop-in offers with potential future clients on www.theburnstudio.com/membership, available for purchase beginning August 30, 2024. Everyone can also take advantage of the great August drop-ins at just $10 per class!'
    },
    {
        question: 'Is there any action required on my part for the transition to the new fitDEGREE account?',
        answer: 'A couple simple steps - when you open The Burn app (previously the KHY app) on August 30, you may have to verify your account. If you can’t find the app, please go to your app store and search “The Burn fitDEGREE”. When you register for a class, you’ll have to e-sign our liability and media waiver.',
        answer2: 'Please note that you can also use the general fitDEGREE app and search fit spot “The Burn”.'
    },
    {
        question: 'Will the transition to The Burn’s fitDEGREE account affect our ability to book classes during the transition period?',
        answer: 'You can book classes through your current fitDEGREE account through August 29. On August 30, you’ll be able to book classes for August 30 and beyond after you’ve taken the steps in the above question/answer.',
    },
    {
        question: 'What should we do if we encounter issues with our fitDEGREE account on August 30 or beyond?',
        answer: 'Please reach out to us directly via e-mail (kristi@theburnstudio.com / rose@theburnstudio.com) or social media (links found below).',
    },
    {
        question: 'Will the class schedule remain the same during and after the transition?',
        answer: 'Yes – for the most part! We will be adding more classes to the schedule beginning in September – so keep an eye out for more class options! We will keep all the same class types and hope to add more class types soon. The previously titled combo classes such as Sculpt/Pilates or Barre/Pilates will now be titled Burn Fusion. Check out our class descriptions at www.theburnstudio.com/classes',
    },
    {
        question: 'Are all current instructors staying on with The Burn, or will there be any changes in the teaching staff?',
        answer: 'Yes, we are happy to share that all our amazing instructors will stay on with The Burn! AND, you’ll even see a few familiar faces from the past :) We will be continuing to look for more instructors, so if you are interested or have someone to refer – please e-mail us.',
    },
    {
        question: 'Will there be any changes in the class booking process or policies under The Burn?',
        answer: 'No changes. Please sign up as soon as possible for classes. There is still a $10 fee for late cancel (within 2 hours prior to class start) and $10 fee for no show.',
    },
    {
        question: 'Are there any changes to the memberships we should be aware of?',
        answer: 'We are excited to add Guest Passes to those with 6- and 12-month commitment memberships! We will also be providing one Guest Pass to all current members if you attend a class in September to share with friends, family, and anyone who you think may consider joining us at The Burn!'
    },
    {
        question: 'Is there an opportunity to meet Kristi and Rose in person to discuss any concerns or questions we might have about the transition?',
        answer: 'Yes! Check out our office hours on the website FAQ.'
    }
]

export default function FAQ() {
  return (
    <Grid container>
        <Grid item xs={12}>
            <Box p={2}>
                <Typography variant="h3" component="h2" gutterBottom={true}>
                    <Typography variant="h3" component="span" color="primary">Transition Frequently Asked Questions</Typography>
                </Typography>
            </Box>
        </Grid>
        {questions.map((q, i) => (
            <Grid item xs={12}>
                <Box p={2}>
                    <Accordion key={i}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${i}-content`}
                            id={`panel${i}-header`}
                        >
                            <Typography color="primary" sx={{ "fontWeight": "bold"}}>{q.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {q.answer}
                            </Typography>
                            {q.answer2 && <Typography>{q.answer2}</Typography>}
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Grid>
        ))}
       
    </Grid>
  );
}