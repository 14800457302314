import { Grid, Box, Button, Typography } from "@mui/material"
import React from "react"
import logo from '../assets/the_burn_primary_logo_fullcolor_RGB.png'
import movie from '../assets/shortenVid.mp4'
// import barre from '../assets/IMG_0265.jpeg'
import barre from '../assets/TK2_7760.jpg'
import sculpt from '../assets/SCUPLT III.jpg'

// let sculpt = 'https://storage.googleapis.com/theburnpublic/SCUPLT%20III.jpg'
// let movie = 'https://storage.googleapis.com/theburnpublic/ScupltBlend.mp4'
// let barre = 'https://storage.googleapis.com/theburnpublic/BARRE%20PIC%201.jpg'


const LandingPage = () => {

    return (
        <Grid container>
            <Grid item xs={12}>
                <video id="preloader-vid" src={movie} autoPlay loop muted playsInline controls={false} />
            </Grid>
            <Grid item xs={12} padding={10} sx={{ textAlign: 'center'}}>
                <Box
                    component="img"
                    sx={{
                        height: 'auto',
                        width: '100%',
                        maxWidth: 500,
                    }}
                    alt="Logo."
                    src={logo}
                />
            </Grid>
            <Grid item md={1}/>
            <Grid item xs={12} md={4} padding={2}>
                <Button
                    color="primary"
                    sx={{ width: '100% !important'}}
                    href="/classes/schedule"
                    size="large"
                    variant="contained">
                    Class Schedule
                </Button>
            </Grid>
            <Grid item xs={12} md={2}/>
            <Grid item xs={12} md={4} padding={2}>
                <Button
                    color="primary"
                    sx={{ width: '100% !important'}}
                    href="/membership"
                    size="large"
                    variant="contained">
                    Membership Options
                </Button>
            </Grid>
            <Grid item md={1}/>
            <Grid item xs={12} paddingTop={5}/>
            <Grid item xs={12} md={6} textAlign={"center"} alignItems={"center"} padding={5}>
                <Box
                    component="img"
                    sx={{
                        height: 'auto',
                        width: '100%',
                        maxWidth: 400,
                        borderRadius: '50%'
                    }}
                    alt="Logo."
                    src={sculpt}
                />
            </Grid>
            <Grid item xs={12} md={6} padding={5}>
                <Typography variant="h3" component="h2" gutterBottom={true}>
                    All Levels Welcome
                </Typography>
                <Typography variant="h6" component="h2" gutterBottom={true}>
                    No matter where you are in your journey - beginner-intermediate-advanced – you are welcome at The Burn! Modifications and a welcoming community included, making our classes accessible and fun for all levels.
                </Typography>
            </Grid>
            <Grid item xs={12} paddingTop={5}/>
            <Grid item xs={12} md={6} padding={5}>
                <Typography variant="h3" component="h2" gutterBottom={true}>
                    Feel The Burn!
                </Typography>
                <Typography variant="h6" component="h2" gutterBottom={true}>
                All classes are heated between 95-105 degrees unless otherwise specified in the class description. No matter how you come to sweat with us, you will feel the burn.
                </Typography>
            </Grid>
            <Grid item xs={12} md={6} textAlign={"center"} alignItems={"center"} padding={5}>
                <Box
                    component="img"
                    sx={{
                        height: 'auto',
                        width: '100%',
                        maxWidth: 400,
                        borderRadius: '50%'
                    }}
                    alt="Logo."
                    src={barre}
                />
            </Grid>
        </Grid>
        

    )
}

export default LandingPage