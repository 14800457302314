import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Router from './Router';
import { ThemeProvider, createTheme } from '@mui/material';


const theme = createTheme({
  palette: {
    error: {
      main: '#f40c34', // f40c34
      contrastText: '#fff',
    },
    secondary: {
      main: '#91B3AF', // 91B3AF
      contrastText: '#fff',
    },
    primary: {
      main: '#EC1A36', // 841010
      contrastText: '#fff',
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  </React.StrictMode>
);
