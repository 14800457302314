import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid } from '@mui/material';

const questions = [
    {
        question: 'Are all the classes hot?',
        answer: 'All of the classes inside our Yoga/Barre studio are HOT.   In the yoga and barre studios the temperatures will range from 95 – 105 degrees.  Humidity will run anywhere from 30 – 60%.  The more people the hotter the temperature and higher humidity!',
    },
    {
        question: 'Are the classes beginner friendly?',
        answer: 'Yes, all our classes are multi-level.  Depending on the instructor style and class type, some classes will be more intense than others.  If you have any physical limitations, please let your instructor know.',
    },
    {
        question: 'How soon before class should I arrive?',
        answer: 'Arrive at the studio at least 15 minutes prior to the start of class.  If you are new, please arrive 20 minutes prior to meet the instructor and get oriented to the studio.  The doors to the studio will lock 5 minutes prior to the start of class, and we will not be able to accommodate late arrivals.  Some classes fill up quickly; make sure you register early to get a spot.',
    },
    {
        question: 'What hours are you open?',
        answer: 'Currently we will have office hours available 15 minutes prior to class as well as Saturday 8:00am -10:00am and Sunday 8:30am-10:30am.  Rose and Kristi are also available by appointment to meet clients at the studio for tour and class description outside of these hours.',
    },
    {
        question: 'What do I wear/bring to class?',
        answer: 'Wear what you usually would for any yoga or exercise class: capris or tights (short or long); cami-tank or sport bra.  Men can wear shorts or tights. Shirts are optional in class.  We will use a mat in all classes including barre. Bring a yoga mat, sweat towel, and water bottle.',
    },
    {
        question: 'Do you have showers?',
        answer: 'Yes, we have two shower rooms located in the studio.  One in the men’s room and one in the women’s room.',
    },
    {
        question: 'Do I need to sign up beforehand?',
        answer: 'Yes! Download and create an account on our branded app – The Burn, Fit Degree.  You may sign up for classes as far out as your current membership or month expires. Occasionally we can take drop-ins, but we highly encourage you to sign up online beforehand to guarantee a spot in class. If you are unable to make your scheduled class, please sign in to your account and CANCEL your reservation up to 2 hours prior to class or your account will be charged $10 for that class. No shows are also a $10 charge. Please download and create your account on our The Burn app on your phone to make signing up for classes that much easier and faster!',
    },
]

export default function FAQ() {
  return (
    <Grid container>
        <Grid item xs={12}>
            <Box p={2}>
                <Typography variant="h3" component="h2" gutterBottom={true}>
                    <Typography variant="h3" component="span" color="primary">Frequently Asked Questions</Typography>
                </Typography>
            </Box>
        </Grid>
        {questions.map((q, i) => (
            <Grid item xs={12}>
                <Box p={2}>
                    <Accordion key={i}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${i}-content`}
                            id={`panel${i}-header`}
                        >
                            <Typography color="primary" sx={{ "fontWeight": "bold"}}>{q.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {q.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Grid>
        ))}
       
    </Grid>
  );
}