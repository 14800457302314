import React from 'react';

import { Grid, Box, Typography, Card, CardContent,
         CardHeader, Button, Link } from '@mui/material';

export default function DropInCard ({ key, title, price, description, expires, url }) {
    const handleRedirect = (url) => {
        window.open(url, 'mozillaTab');
      }
  return (

    <Card variant="outlined" sx={{ textAlign: 'center', maxWidth: 300}}>
        <CardHeader title={title} className={{paddingTop: 3}}></CardHeader>
        <CardContent>
            <Box sx={{paddingBottom: 2}}>
            <Typography variant="h3" component="h2" gutterBottom={true}>
                {price}
            </Typography>
            <Typography color="textSecondary" variant="subtitle1" component="p">
                {description}
            </Typography>
            <Typography color="textSecondary" variant="subtitle1" component="p">
                Expires {expires}
            </Typography>
            </Box>
            <Grid container>
                <Grid item xs={12} direction={"column"} alignItems={"center"}>
                    <Button variant='contained' onClick={() => handleRedirect(url)}>
                        Sign Up
                    </Button>
                </Grid>
            </Grid>
            <Box mt={1}>
                <Link onClick={() => handleRedirect(url)} color="primary">
                    Learn more
                </Link>
            </Box>
        </CardContent>
    </Card>

  );
}
