import React from "react"
import { Grid, Typography } from "@mui/material"
import ClassInfoCard from "./ClassInfoCard"
import bootcamp from '../assets/YOGA PIC II.jpg'
import flow from '../assets/TK2_7814-Enhanced-NR.jpg'
import pilates from '../assets/SCUPLT II-edit.jpg'
import sculpt from '../assets/SCUPLT I.jpg'
import fusion from '../assets/PUMP PIC II OR FUSION.jpg'
import pump from '../assets/PUMP PIC I.jpg'
import barre from '../assets/BARRE PIC II.jpg'
import candlelight from '../assets/IMG_5038.jpeg'

const classInfo = [
    {
        id: 1,
        name: "Burn Sculpt",
        description: "Combining elements of Pilates, yoga, and cardio into one amazing workout, including weighted movements and cardio bursts.",
        img: sculpt,
        searchString: "Burn Sculpt"
    },
    {
        id: 2,
        name: "Burn Barre",
        description: "Combining movements from yoga, Pilates, sculpt, and ballet into one great exercise routine that will elongate and tone your muscles. These classes will work on controlled and isolated movements to go deep into the muscles, utilizing a ballet barre and incorporating several different modalities of fitness.",
        img: barre,
        searchString: "Burn Barre"
    },
    {
        id: 3,
        name: "Burn Bootcamp",
        description: "This class will have you firing on all cylinders! We'll mix it up every time, combining movements from barre, sculpt, pump and cardio.",
        img: bootcamp,
        searchString: "Burn Bootcamp"
    },
    {
        id: 4,
        name: "Burn Flow",
        description: "Our signature yoga flow in which each instructor brings their own unique style to the class. Every time you step on your mat is a truly authentic experience. This vinyasa flow-inspired class will teach you how to incorporate your breath and movement. Burn Flow is great for beginners as well as the experienced yogi.",
        img: flow,
        searchString: "Burn Flow"
    },
    {
        id: 5,
        name: "Burn Pilates",
        description: "Pilates is a low-impact exercise method emphasizing core stability, posture alignment, and controlled, precise movements. Performed on a mat, Burn Pilates utilizes your body weight and small props to challenge and enhance your practice.",
        img: pilates,
        searchString: "Burn Pilates"
    },
    {
        id: 6,
        name: "Burn Pump",
        description: "Burn Pump is a high-energy, full-body strength training workout designed to sculpt, tone, and strengthen your muscles utilizing cardio barbell sets. Set to motivating music, this class targets all major muscle groups, providing a comprehensive and effective workout that will leave you feeling empowered and energized.",
        img: pump,
        searchString: "Burn Pump"
    },
    {
        id: 7,
        name: "Burn Candlelight Flow",
        description: "Connect your breath, mind, spirit and body in our yoga candlelit flow class. Lights are dim, candles are lit - an opportunity to allow yourself to block out the distractions and give yourself permission to dive deeper into your practice.",
        img: candlelight,
        searchString: "Burn Candlelight Yoga"
    },
    {
        id: 8,
        name: "Burn Fusion",
        description: "Burn Fusion classes are instructors’ choice of a combination of two of our signature class's styles - Pump/Sculpt, Pilates/Barre, Sculpt/Barre. No matter what combo, you’ll feel the Burn.",
        img: fusion,
        searchString: "Burn Fusion"
    },
]

const ClassInfo = () => {
    return (
        <Grid container>
            <Grid item xs={12} paddingTop={5} textAlign={"center"}>
                <Typography variant="h3" component="h2" gutterBottom={true}>
                    <Typography variant="h3" component="span" color="primary">Our Classes</Typography>
                </Typography>
            </Grid>
                {classInfo.map((item) => {
                    return <Grid item xs={12} md={6} lg={4} padding={5}>
                                <ClassInfoCard
                                    key={item.id}
                                    title={item.name}
                                    description={item.description}
                                    searchString={item.searchString}
                                    image={item.img}/>
                            </Grid>
                })}
        </Grid>

    )
}

export default ClassInfo