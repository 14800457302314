import { BrowserRouter, Routes, Route } from "react-router-dom"
import LandingPage from "./Pages/LandingPage"
import NotFound from "./Pages/NotFound"
import Membership from "./Pages/Membership"
import ClassInfo from "./Pages/ClassInfo"
import ClassSchedule from "./Pages/ClassSchedule"
import About from "./Pages/About"
import SiteAppBar from "./SiteAppBar"
import { Grid } from "@mui/material"
import Footer from "./Footer"
import FAQ from "./Pages/FAQ"
import TransitionFAQ from "./Pages/TransitionFAQ"

const Router = () => {
    return (
        <BrowserRouter>
            <Grid sx={{ position: 'relative', minHeight: '100vh'}}>
                <SiteAppBar />
                <Grid container>
                    <Grid item xs={12} paddingBottom={25}>
                        <Routes>
                            <Route path="/" element={<LandingPage />} />
                            <Route path="*" element={<NotFound />} />
                            <Route path="/classes" element={<ClassInfo />} />
                            <Route path="/membership" element={<Membership />} />
                            <Route path="/classes/schedule" element={<ClassSchedule />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/faq" element={<FAQ />} />
                            <Route path="/transition/faq" element={<TransitionFAQ />} />
                        </Routes>
                    </Grid>
                </Grid>
                <Footer />
            </Grid>
        </BrowserRouter>
    )
}

export default Router
