import React, {useEffect, useState} from "react"
import { Grid, TextField, FormControlLabel, Checkbox, Link } from '@mui/material'
import AppBar from '@mui/material/AppBar';
import Modal from '@mui/material/Modal';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation} from 'react-router-dom';
import Box from '@mui/material/Box';
import logo from './assets/the_burn_primary_logo_white_RGB.png'
import otherLogo from './assets/the_burn_primary_logo_fullcolor_RGB.png'
import CloseIcon from '@mui/icons-material/Close';


const pages = [
    {"name": "Classes", "path": "class"},
    {"name": "Schedule", "path": "schedule"},
    {"name": "Membership", "path": "membership"},
    {"name": "About Us", "path": "about"},
    {"name": "FAQ", "path": "faq"},
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};


export default function SiteAppBar() {
    const [navBackground, setNavBackground] = useState(true)
    const [currentPath, setCurrentPath] = useState(window.location.pathname)
    const location = useLocation()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loginModalOpen, setLoginModalOpen] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const handleSubmit = (event) => {
        event.preventDefault();
        if (email && password) {
            console.log("not sending yet....")
        }
    }

    const handleOpenNavMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseNavMenu = (linkType) => {
        setAnchorEl(null);
        switch (linkType) {
            case "class":
                window.location.href = "/classes"
                break;
            case "schedule":
                window.location.href = "/classes/schedule"
                break;
            case "membership":
                window.location.href = "/membership"
                break;
            case "about":
                window.location.href = "/about"
                break;
            case "faq":
                window.location.href = "/faq"
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        setCurrentPath(location.pathname)
        if (location.pathname !== "/" | window.scrollY > 20) {
            setNavBackground(false)
        } else {
            setNavBackground(true)
        }
    }, [location])

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY < 20 && currentPath === "/") {
                setNavBackground(true)
            } else {
                setNavBackground(false)
            }
        }
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [currentPath])

    return (
        <div>
            <AppBar color={navBackground ? 'transparent' : 'primary'} postion="fixed">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Button onClick={() => handleCloseNavMenu()} href='/'>
                            <Box
                                component="img"
                                sx={{
                                    height: 40,
                                    width: 183,
                                }}
                                alt="Logo."
                                src={logo}
                                />
                        </Button>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: "right" }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={() => handleCloseNavMenu()}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}>
                                {pages.map(page => (
                                    <MenuItem key={page.name} onClick={() => handleCloseNavMenu(page.path)}>
                                        <Typography textAlign="center">{page.name}</Typography>
                                    </MenuItem>
                                ))}
                                <MenuItem key='login' onClick={() => setLoginModalOpen(true)}>
                                    <Typography textAlign="center">Login</Typography>
                                </MenuItem>
                            </Menu>
                            <Modal
                                open={loginModalOpen}
                                onClose={() => setLoginModalOpen(false)}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                >
                                <Box sx={style}>
                                <Grid container>
                                    <Grid item xs={12} align={"right"}>
                                        <IconButton onClick={() => setLoginModalOpen(false)}>
                                            <CloseIcon fontSize="small"/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction={"column"}
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ verticalAlign: "middle"}}>
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 80,
                                            width: 360,
                                            mt: 10
                                        }}
                                        alt="Logo."
                                        src={otherLogo}
                                        />
                                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, padding: 5 }}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                            autoFocus
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="current-password"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox value="remember" color="primary" />}
                                            label="Remember me"
                                        />
                                        <Button
                                            type="submit"
                                            disabled
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                        >
                                            Sign In (Coming Soon)
                                        </Button>
                                        <Grid container>
                                        <Grid item xs>
                                            <Link href="#" variant="body2">
                                            Forgot password?
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Link href="#" variant="body2">
                                                {"Don't have an account? Sign Up"}
                                            </Link>
                                        </Grid>
                                        </Grid>
                                    </Box>
                                    </Grid>
                                    
                                </Box>
                            </Modal>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: "right" }}>
                            {pages.map((page) => (
                            <Button
                                key={page.name}
                                onClick={() => handleCloseNavMenu(page.path)}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page.name}
                            </Button>
                            ))}
                            <Button
                                key='login'
                                onClick={() => setLoginModalOpen(true)}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Login
                            </Button>
                        </Box>
                    </Toolbar>
                </Container>    
            </AppBar>
            {!navBackground && <Toolbar />}
        </div>
    )
}
