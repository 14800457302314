import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Box, Typography, Avatar } from '@mui/material';
import { Map, Marker } from "pigeon-maps"
import rosieKeg from '../assets/Rose and Kristi.jpg'

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    maxWidth: 350,
}));

export default function About(props) {
  
  const content = {
    'header': 'Greetings from The Burn!',
    'description': "We are Kristi Gaskill and Rose Gillen, owners of The Burn. We both have been fitness enthusiasts for years and enjoy incorporating heated fitness into our everyday routines. Our friendship was established many years ago after Kristi convinced Rose (who was skeptical she could handle the heat) to join her for a class at Kris' Hot Yoga (now The Burn) - and the rest is history. We would love it if The Burn became a part of your lifestyle - come meet us at the studio!",
  };

  return (

    <Grid container>
        <Grid item xs={12} padding={2} paddingTop={10} paddingBottom={10}>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Box maxWidth={768}>
                        <Typography variant="h4" component="h2" gutterBottom={true}>{content['header']}</Typography>
                        <Typography variant="subtitle1" color="textSecondary">{content['description']}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container item align={'center'} >
                        <Grid item xs={12} justifyContent={'center'}>
                            <StyledAvatar alt="" src={rosieKeg} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container item xs={12}>
            <Grid item xs={12} md={8} textAlign={"center"} paddingLeft={10} paddingRight={10}>
                <Typography variant="h4" component="h2" gutterBottom={true}>Our Location</Typography>
                <Map height={400} defaultCenter={[41.704400, -93.626800]} defaultZoom={16}>
                    <Marker width={50} anchor={[41.704400, -93.626800]} />
                </Map>
            </Grid>
            <Grid container item xs={12} md={4} height={400} direction={"column"} textAlign={"center"}>
                <Grid item height={200}  paddingTop={15}>
                    <Typography variant="h6" component="h4">Address</Typography>
                    <Typography variant="body1" component="p">2505 SW White Birch Dr., Suite A&B</Typography>
                    <Typography variant="body1" component="p">Ankeny, IA 50023</Typography>
                </Grid>
                <Grid item height={200}  paddingTop={15}>
                    <Typography variant="h6" component="h4">Contact</Typography>
                    <Typography variant="body1" component="p">515-216-3888</Typography>
                </Grid>

            </Grid>
        </Grid>
    </Grid>
  );
}