import axios from "axios"

export default axios.create({
  baseURL: "https://api.fitdegree.com",
  responseType: 'json',
  timeout: 10000,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    'Accept': '*/*',
  }
})